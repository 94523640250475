import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  ToggleButtonGroup,
  ToggleButton,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import conf from "../../config/conf_sql.json";
import useCrewData from "../Common/CrewData";
import ApiHeader from "../Common/ApiHeader";
import SortIcon from "@mui/icons-material/Sort";
import "./animation.css"; // CSS 파일 import
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);
import ErrorHandler from "../Common/ErrorHandler";

function CrewList(props) {
  const {
    api,
    lst_col,
    activity_id,
    activity_time,
    activity_subgroup,
    activity_owner,
    text,
    club_nick,
    activityChanged,
    correctPassword,
  } = props;

  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);
  const [crewList, setCrewList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const subgroups = activity_subgroup ? activity_subgroup.split("\n") : [];
  const [levels, setLevels] = useState({});
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const [subgroupOpen, setSubgroupOpen] = useState(false);
  const [selectedCrewId, setSelectedCrewId] = useState(null);
  const [selectedSubgroup, setSelectedSubgroup] = useState(null);
  const [animate, setAnimate] = useState(false);
  const [attendOpen, setAttendOpen] = useState(false);
  const [pwopen, setpwOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [pendingAttendance, setPendingAttendance] = useState(null);
  const [pwError, setPwError] = useState("");
  const [attempts, setAttempts] = useState(0);
  const totalAttempts = 5;

  const [selectedCrew, setSelectedCrew] = useState(null);
  const [newState, setNewState] = useState(""); // 출석 상태 저장

  // 1. 데이터 가져오기 및 상태 관리
  useEffect(() => {
    ApiHeader.get(api)
      .then((response) => {
        setCrewList(response.data);
      })
      .catch((e) => {
        ErrorHandler(e, "CrewList.js, useEffect, ApiHeader.get");
      });
  }, [activityChanged]);

  useEffect(() => {
    ApiHeader.get(`/api/v1/clubs/nick/${club_nick}`)
      .then((response) => {
        setLevels(response.data.levels && JSON.parse(response.data.levels));
      })
      .catch((e) => {
        ErrorHandler(e, "CrewList.js, useEffect, club_nick, ApiHeader.");
      });
  }, [club_nick]);

  // 2. 정렬 및 필터링 처리
  const handleSort = (key) => {
    const json_key = {
      이름: "nick",
      생일: "birthday",
      가입일: "created_time",
      소그룹: "subgroup",
      크루등급: "admin_level",
    };

    const isAsc =
      sortConfig.key === json_key[key] && sortConfig.direction === "ascending";
    setSortConfig({
      key: json_key[key],
      direction: isAsc ? "descending" : "ascending",
    });
  };

  const sortedData = React.useMemo(() => {
    const crewIdIndex = crewList.findIndex(
      (row) => row.crew_id === hashids.decode(crewId)[0]
    );
    if (crewIdIndex > 0) {
      const crewIdRow = crewList.splice(crewIdIndex, 1);
      crewList.unshift(crewIdRow[0]);
    }

    if (!sortConfig.key) return crewList;
    return [...crewList].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }, [crewList, sortConfig]);

  const filterSortData = sortedData.filter(
    (row) =>
      (row.nick ? row.nick.toLowerCase().includes(searchTerm) : false) ||
      (levels[row.admin_level]
        ? levels[row.admin_level].toLowerCase().includes(searchTerm)
        : false)
  );

  const handleChange = (e) => {
    try {
      setSearchTerm(e.target.value.toLowerCase());
    } catch (e) {
      ErrorHandler(e, "CrewList.js, handleChange");
    }
  };

  // 3. 소그룹 변경 관련 처리
  const handleSubgroupOpenDialog = (event, crewId) => {
    setSelectedCrewId(crewId);
    setSelectedSubgroup(event.target.value);
    setSubgroupOpen(true);
  };

  const handleSubgroupConfirmChange = () => {
    handleSubgroupChange(selectedSubgroup, selectedCrewId);
    setSubgroupOpen(false);
  };

  const handleSubgroupChange = async (newSubgroup, id) => {
    setCrewList((prevList) =>
      prevList.map((row) =>
        row.crew_id === id ? { ...row, subgroup: newSubgroup } : row
      )
    );

    try {
      await ApiHeader.put(
        `/api/v1/attendees/${hashids.encode(id)}/${activity_id}/subgroup`,
        {
          subgroup: newSubgroup,
        }
      );
    } catch (e) {
      ErrorHandler(e, "CrewList.js, handleSubgroupChange, attendees put");
    }
  };

  // 4. 출석 관련 처리
  const handleAttendee = async (id, newAlignment) => {
    const newState =
      newAlignment === "attend" ? 2 : newAlignment === "noshow" ? 0 : 1;

    setCrewList((prevList) =>
      prevList.map((row) =>
        row.crew_id === id ? { ...row, state: newState } : row
      )
    );

    try {
      await ApiHeader.put(
        `/api/v1/attendees/${hashids.encode(id)}/${activity_id}`,
        {
          state: newState,
        }
      );
    } catch (e) {
      ErrorHandler(e, "CrewList.js, handleAttendee, attendees put");
    }
  };

  const handleAttendClickOpen = (crew_id, newAlignment) => {
    setSelectedCrew(crew_id);
    setNewState(newAlignment);
    setAttendOpen(true); // 다이얼로그 열기
  };

  const handleAttendConfirmChange = () => {
    handleAttendee(selectedCrew, newState); // 확인 클릭 시 상태 변경
    setAttendOpen(false); // 다이얼로그 닫기
  };

  const animationHeart = () => {
    setAnimate(true);
    setTimeout(() => setAnimate(false), 1000);
  };

  const handlepwClickOpen = (crew_id, event, state, newAlignment) => {
    setPendingAttendance({ crew_id, event, state, newAlignment });
    state === 1 && setpwOpen(true);
    state === 2 && animationHeart();
  };

  const handlepwClose = () => {
    setpwOpen(false);
    setPassword("");
    setPendingAttendance(null);
    setPwError("");
  };

  const handlepwConfirm = () => {
    if (password === correctPassword.toString()) {
      const { crew_id, newAlignment } = pendingAttendance; // event와 state는 필요 없음
      // newAlignment에 따라 출석 상태를 변경
      handleAttendee(crew_id, newAlignment);

      // 출석 완료 애니메이션 실행
      if (newAlignment === "attend") {
        animationHeart();
      }

      handlepwClose(); // 다이얼로그 닫기
    } else {
      setPwError("코드가 틀렸습니다. 다시 시도하세요.");
      setAttempts((prev) => prev + 1);

      // 시도 횟수가 초과되면 노쇼로 처리
      if (attempts + 1 >= totalAttempts) {
        const { crew_id } = pendingAttendance;
        handleAttendee(crew_id, "noshow");
        handlepwClose();
      }
    }
  };

  const handlepwChange = (event) => {
    setPassword(event.target.value);
    setPwError("");
  };

  // 5. 시간 조건 확인
  const isSubgroupChangeTime = (activityTime) => {
    const now = moment();
    const end = moment(activityTime).subtract(conf.time_offset).add(1, "hours");
    return now.isBefore(end);
  };

  const isOrganizerCheckinTime = (activityTime) => {
    const now = moment();
    const start = moment(activityTime)
      .subtract(conf.time_offset)
      .subtract(1, "hours");
    const end = moment(activityTime).subtract(conf.time_offset).add(6, "hours");
    return now.isBetween(start, end);
  };

  const isMemberCheckInTime = (activityTime) => {
    const now = moment();
    const start = moment(activityTime)
      .subtract(conf.time_offset)
      .subtract(60, "minutes");
    const end = moment(activityTime)
      .subtract(conf.time_offset)
      .add(60, "minutes");
    return now.isBetween(start, end);
  };

  // 6. UI 렌더링
  const tableData = filterSortData.map((row) => (
    <TableRow key={row.id}>
      <TableCell>
        {row.admin_level >= 9 ? "🥇" : row.admin_level >= 7 ? "🥈" : ""}
        <Link
          to={`/${club_nick}/crew/${hashids.encode(row.crew_id)}`}
          style={{ color: "#3f50b5", textDecoration: "inherit" }}
        >
          {row.nick}
        </Link>
      </TableCell>
      <TableCell>{levels[row.admin_level]}</TableCell>
      <TableCell>
        {moment(row.created_time)
          .subtract(conf.time_offset)
          .format("YYYY.MM.DD")}
      </TableCell>
    </TableRow>
  ));

  const tableAttendeeData = filterSortData.map((row) => (
    <TableRow key={row.id}>
      <TableCell key={row.id} sx={{ minWidth: 48 }}>
        {row.admin_level >= 9
          ? "🥇"
          : row.admin_level >= 7
          ? "🥈"
          : row.admin_level >= 5
          ? "*"
          : row.admin_level === 1
          ? "¹"
          : ""}
        {adminLevel >= 3 || row.crew_id === hashids.decode(crewId)[0] ? (
          <Link
            to={`/${club_nick}/crew/${hashids.encode(row.crew_id)}`}
            style={{ color: "#3f50b5", textDecoration: "inherit" }}
          >
            {row.nick}
          </Link>
        ) : (
          row.nick
        )}
      </TableCell>
      <TableCell key={row.subgroup} size="small">
        {activity_subgroup &&
        activity_subgroup.split("\n").length > 1 &&
        isSubgroupChangeTime(activity_time) &&
        (adminLevel >= 8 ||
          activity_owner === hashids.decode(crewId)[0] ||
          row.crew_id === hashids.decode(crewId)[0]) ? (
          <Select
            value={row.subgroup}
            onChange={(event) => handleSubgroupOpenDialog(event, row.crew_id)}
            size="small"
          >
            {[...new Set([row.subgroup, ...subgroups])].map((group, index) => (
              <MenuItem key={index} value={group}>
                {group}
              </MenuItem>
            ))}
          </Select>
        ) : (
          row.subgroup
        )}
      </TableCell>
      <TableCell
        key={"ToggleButtonGroup"}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {(adminLevel >= 7 || activity_owner === hashids.decode(crewId)[0]) &&
        isOrganizerCheckinTime(activity_time) ? (
          <ToggleButtonGroup
            color="primary"
            value={row.state === 2 ? "attend" : row.state === 0 ? "noshow" : ""}
            exclusive
            onChange={
              (event, newAlignment) =>
                handleAttendClickOpen(row.crew_id, newAlignment) // 다이얼로그 열기
            }
            aria-label="Platform"
            size="small"
          >
            <ToggleButton value="attend" style={{ width: "70px" }}>
              {row.state === 2 ? "출석!" : "출석?"}
            </ToggleButton>
          </ToggleButtonGroup>
        ) : row.crew_id === hashids.decode(crewId)[0] &&
          isMemberCheckInTime(activity_time) &&
          row.state > 0 ? (
          <div>
            {animate && (
              <div className="attendance-animate-overlay">
                <div className="heart"></div>
                <div className="attendance-animate-text">출석 완료</div>
              </div>
            )}
            <ToggleButtonGroup
              color="primary"
              value={
                row.state === 2 ? "attend" : row.state === 0 ? "noshow" : ""
              }
              exclusive
              onChange={(event, newAlignment) =>
                handlepwClickOpen(row.crew_id, event, row.state, newAlignment)
              }
              aria-label="Platform"
              size="small"
            >
              <ToggleButton value="attend" style={{ width: "70px" }}>
                {row.state === 2 ? "출석!" : "출석?"}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        ) : row.state === 2 ? (
          "출석"
        ) : row.state === 0 ? (
          "노쇼"
        ) : (
          "신청"
        )}
      </TableCell>
    </TableRow>
  ));

  return (
    <div>
      <Toolbar key="tb">
        <Typography key="tg1" variant="h7">
          {text}
        </Typography>
        <Typography
          key="tg2"
          noWrap
          component="div"
          sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
        />
        &ensp; <SearchIcon />
        <InputBase
          key="ib"
          type="text"
          className="search"
          onChange={handleChange}
          placeholder="Search..."
        />
      </Toolbar>
      <Table key="t">
        <TableHead key="th">
          <TableRow key="tr">
            {lst_col.map((c) => (
              <TableCell
                key={c}
                style={{ color: "#3f50b5" }}
                onClick={() => handleSort(c)}
              >
                {c}
                {c === "" ? "" : <SortIcon sx={{ fontSize: 15 }} />}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {lst_col[2] === "가입일" ? tableData : tableAttendeeData}
        </TableBody>
      </Table>

      {/* 소그룹 변경 다이얼로그 */}
      <Dialog open={subgroupOpen} onClose={() => setSubgroupOpen(false)}>
        <DialogTitle>소그룹 변경</DialogTitle>
        <DialogContent>
          <DialogContentText>
            선택한 소그룹으로 변경하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSubgroupOpen(false)} color="primary">
            취소
          </Button>
          <Button onClick={handleSubgroupConfirmChange} color="primary">
            확인
          </Button>
        </DialogActions>
      </Dialog>

      {/* 출석 변경 다이얼로그 */}
      <Dialog open={attendOpen} onClose={() => setAttendOpen(false)}>
        <DialogTitle>출석 상태 변경</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {newState === "attend" ? "출석" : "미출석"}으로 변경하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAttendOpen(false)} color="primary">
            취소
          </Button>
          <Button onClick={handleAttendConfirmChange} color="primary">
            확인
          </Button>
        </DialogActions>
      </Dialog>

      {/* 출석 코드 다이얼로그 */}
      <Dialog open={pwopen} onClose={handlepwClose}>
        <DialogTitle>Attendance Code</DialogTitle>
        <DialogContent>
          <DialogContentText>
            운영진에게 출석 코드를 문의하세요.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            fullWidth
            value={password}
            onChange={handlepwChange}
          />
          {pwError && (
            <>
              <Typography color="error">{pwError}</Typography>
              <Typography variant="body2">
                남은 시도 횟수: {totalAttempts - attempts}
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handlepwClose} color="primary">
            취소
          </Button>
          <Button onClick={handlepwConfirm} color="primary">
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CrewList;
