import React from "react";
import { Link } from "react-router-dom";
import { Toolbar, Typography, Grid } from "@mui/material";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <Toolbar
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Typography variant="h5" component="div" sx={{ mr: 2 }}>
            <Link
              to={"/"}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              Crew<strong>WITH</strong>
            </Link>
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="flex-start">
            <Typography variant="caption" component="div">
              &copy; {currentYear} CrewWITH. All rights reserved.
            </Typography>
            <Typography variant="caption" component="span">
              <Link
                to={"/termsofservice"}
                style={{ color: "#3f50b5", textDecoration: "inherit" }}
              >
                서비스 이용약관
              </Link>{" "}
              및{" "}
              <Link
                to={"/privacypolicy"}
                style={{ color: "#3f50b5", textDecoration: "inherit" }}
              >
                개인정보 처리방침
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default Footer;
