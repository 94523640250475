import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TopAppBar from "../Main/TopAppBar";
import CrewList from "./CrewList";
import useCrewData from "../Common/CrewData";
import ClubPublicInfo from "../Club/ClubPublicInfo";

function Crews() {
  const { club_nick } = useParams();
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);

  if (adminLevel >= 5) {
    return (
      <div>
        <TopAppBar club_nick={club_nick} />
        <CrewList
          text="Crew"
          order="desc"
          lst_col={["이름", "크루등급", "가입일"]}
          api={`/api/v1/crews/club/${club_nick}/admin-level/10/crews?state=1&sign_name=under`}
          club_nick={club_nick}
        />
      </div>
    );
  } else {
    return (
      <>
        <TopAppBar club_nick={club_nick} />
        <ClubPublicInfo />
      </>
    );
  }
}

export default Crews;
