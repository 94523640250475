import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Stack,
  Paper,
  TableContainer,
  TableHead,
} from "@mui/material";
import TopAppBar from "../Main/TopAppBar";
import moment from "moment";
import "moment/locale/ko";
import conf from "../../config/conf_sql.json";
import useCrewData from "../Common/CrewData";
import ClubLevel from "./ClubLevel";
import ApiHeader from "../Common/ApiHeader";
import ErrorHandler from "../Common/ErrorHandler";

function ClubInfo() {
  const { club_nick } = useParams();
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);

  const navigate = useNavigate();
  const [userInput, setUserInput] = useState("");

  useEffect(() => {
    callApi();
  }, [clubId]);

  const [clubData, setClubData] = useState([]);
  const [memberCount, setMemberCount] = useState(0);

  const callApi = async () => {
    ApiHeader.get(`/api/v1/clubs/nick/${club_nick}`)
      .then((response) => {
        setClubData(response.data);
      })
      .catch((e) => {
        ErrorHandler(e, "ClubInfo.js, get club data");
      });
    ApiHeader.get(`/api/v1/clubs/nick/${club_nick}/crews/count`)
      .then((response) => {
        setMemberCount(response.data.count);
      })
      .catch((e) => {
        ErrorHandler(e, "ClubInfo.js, get club member count");
      });
  };

  const transferClubApi = async () => {
    ApiHeader.put(`/api/v1/clubs/${clubId}/state/0`)
      .then((response) => {
        navigate(`/`);
      })
      .catch((e) => {
        ErrorHandler(e, "ClubInfo.js, delete club");
      });
  };

  const [openClub, setOpenClub] = React.useState(false);
  const eventClubClick = () => {
    setOpenClub(true);
  };
  const clubClose = () => {
    setOpenClub(false);
  };
  const clubAgree = () => {
    transferClubApi();
    setOpenClub(false);
  };

  return (
    <div>
      <TopAppBar club_nick={club_nick} />
      <Typography sx={{ mt: 2, ml: 2, fontSize: 26 }}>클럽 정보</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{ minWidth: 70 }}>클럽 이름</TableCell>
            <TableCell>{clubData.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>클럽 닉네임</TableCell>
            <TableCell>{clubData.nick}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>클럽 카테고리</TableCell>
            <TableCell>{clubData.category}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>클럽 정보</TableCell>
            <TableCell style={{ whiteSpace: "pre-wrap" }}>
              {clubData.info}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>클럽 생성일</TableCell>
            <TableCell>
              {moment(clubData.created_time)
                .subtract(conf.time_offset)
                .format("Y년 M월 D일")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>클럽 회원 수</TableCell>
            <TableCell>{memberCount}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {clubData.levels && (
        <>
          <Typography sx={{ mt: 1, ml: 2 }}>크루 레벨</Typography>
          <TableContainer component={Paper} sx={{ mt: 0.5, mb: 1 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>레벨</TableCell>
                  <TableCell>등급</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(JSON.parse(clubData.levels))
                  .sort(([levelA], [levelB]) => levelB - levelA)
                  .map(([level, grade]) => (
                    <TableRow key={level}>
                      <TableCell>{level}</TableCell>
                      <TableCell>{grade}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <ClubLevel />
        </>
      )}
      {adminLevel >= 8 && (
        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: 1, mr: 3, display: "flex", ml: "auto" }}
          justifyContent="flex-end"
        >
          <Button variant="contained" sx={{ display: "flex", ml: "auto" }}>
            <Link
              to={`/${club_nick}/club/modify`}
              state={{ timeAuthorized: Date.now() }}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              {club_nick.toUpperCase()} 클럽 정보 수정
            </Link>
          </Button>

          {adminLevel >= 9 && (
            <Button
              variant="contained"
              onClick={eventClubClick}
              color="error"
              sx={{ display: "flex", ml: "auto" }}
            >
              {club_nick.toUpperCase()} 클럽 이관
            </Button>
          )}
          <Dialog open={openClub} onClose={clubClose}>
            <DialogTitle id="alert-dialog-title">
              {club_nick.toUpperCase()} 클럽 이관 확인
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                클럽 이관를 진행하려면 "누구에게 클럽을 이관 하겠습니다."라고
                입력해주세요.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="클럽 이관 확인"
                type="text"
                fullWidth
                size="small"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={clubClose}>취소</Button>
              <Button
                onClick={() => {
                  if (userInput === "클럽 이관 하겠습니다.!") {
                    clubAgree();
                  } else {
                    alert("정확한 문구를 입력해주세요.");
                  }
                }}
                autoFocus
              >
                삭제
              </Button>
            </DialogActions>
          </Dialog>
        </Stack>
      )}
    </div>
  );
}

export default ClubInfo;
