import React, { useEffect, useState, useMemo } from "react";
import Calendar from "react-calendar";
import moment from "moment";
import ActivityCardList from "./ActivityCardList";
import "react-calendar/dist/Calendar.css";
import conf from "../../config/conf_sql.json";
import ApiHeader from "../Common/ApiHeader";
import ErrorHandler from "../Common/ErrorHandler";
import useCrewData from "../Common/CrewData";
import "../../App.css";

function ActivityCalendar({ club_nick }) {
  const [activityList, setActivityList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [initialLoad, setInitialLoad] = useState(true);
  const [holidays, setHolidays] = useState({ holidaysWithYear: [], holidaysWithoutYear: [] });
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);
  const [isRestricted, setIsRestricted] = useState(false); // 제한 여부

  useEffect(() => {
    if (crewId === "") return;
    ApiHeader.get(`/api/v1/attendees/activity/crew/${crewId}/cancel/count`)
      .then((response) => {
        if (response.data === null) return;
        response.data.count > 0 && setIsRestricted(true);
      })
      .catch((e) => {
        ErrorHandler(e, "ActivityCardList.js, useEffect");
      });

    ApiHeader.get(`/api/v1/attendees/activity/crew/${crewId}/noshow/count`)
      .then((response) => {
        if (response.data === null) return;
        response.data.count > 0 && setIsRestricted(true);
      })
      .catch((e) => {
        ErrorHandler(e, "ActivityCardList.js, useEffect");
      });
  }, [crewId]);

  useEffect(() => {
    const storedDate = sessionStorage.getItem("selectedDate");
    const storedMonth = moment(new Date(storedDate)).format("YYYY-MM");
    const storedTimestamp = sessionStorage.getItem("selectedDateTimestamp");
    const currentTime = new Date().getTime();
    const currentMonth = moment().format("YYYY-MM");

    if (
      storedDate &&
      storedTimestamp &&
      currentTime - storedTimestamp < 60000
    ) {
      setSelectedDate(new Date(storedDate));

      if (
        storedMonth !== currentMonth &&
        (adminLevel >= 2 || club_nick === "personal")
      ) {
        setInitialLoad(false);
      }
    }

    fetchActivities(currentMonth, initialLoad);
  }, [initialLoad, memberId, clubId, crewId, adminLevel]);

  useEffect(() => {
    fetch("/holidays.json")
      .then((response) => response.json())
      .then((data) => setHolidays(data))
      .catch((e) => console.error("Error loading holidays:", e));
  }, []);

  const getApiUrl = (club_nick, initialLoad, startOfMonth, endOfMonth) => {
    if (memberId === "") return;

    if (club_nick === "all") {
      return `/api/v1/activities`;
    } else if (club_nick === "personal") {
      return initialLoad
        ? `/api/v1/members/${memberId}/activities?start_date=${startOfMonth}&end_date=${endOfMonth}`
        : `/api/v1/members/${memberId}/activities`;
    } else {
      return initialLoad
        ? `/api/v1/clubs/${club_nick}/activities?start_date=${startOfMonth}&end_date=${endOfMonth}`
        : `/api/v1/clubs/${club_nick}/activities`;
    }
  };

  const fetchActivities = async (month, initialLoad) => {
    try {
      let response = [];
      const startOfMonth = moment(month)
        .subtract(6, "days")
        .format("YYYY-MM-DD");
      const endOfMonth = moment(month)
        .add(1, "months")
        .startOf("month")
        .add(6, "days")
        .format("YYYY-MM-DD");

      const apiUrl = getApiUrl(
        club_nick,
        initialLoad,
        startOfMonth,
        endOfMonth
      );

      if (apiUrl) {
        response = await ApiHeader.get(apiUrl);
        setActivityList(response.data);
      }
    } catch (e) {
      ErrorHandler(e, "ActivityCalendar.js > callApi()");
    }
  };

  const memoizedTileContent = useMemo(
    () =>
      ({ date, view }) => {
        if (
          activityList.find(
            (x) =>
              moment(x.activity_time)
                .subtract(conf.time_offset)
                .format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")
          )
        ) {
          return (
            <div className="flex justify-center items-center absoluteDiv">
              <div className="dot"></div>
            </div>
          );
        }
        return null;
      },
    [activityList]
  );

  const handleDateChange = (value) => {
    if (value instanceof Date) {
      setSelectedDate(value);
      sessionStorage.setItem("selectedDate", value.toISOString());
      sessionStorage.setItem("selectedDateTimestamp", new Date().getTime());
    }
  };

  const isHolidayWithoutYear = (date) => {
    const formattedDate = moment(date).format("MM-DD");
    return holidays.holidaysWithoutYear.includes(formattedDate);
  };
  
  const isHolidayWithYear = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    return holidays.holidaysWithYear.includes(formattedDate);
  };
  
  return (
    <div>
      <Calendar
        onChange={handleDateChange}
        onActiveStartDateChange={({ activeStartDate }) => {
          (adminLevel >= 2 || club_nick === "personal") &&
            setInitialLoad(false);
        }}
        value={selectedDate}
        calendarType="gregory"
        formatDay={(locale, date) => moment(date).format("D")}
        tileContent={memoizedTileContent}
        tileClassName={({ date, view }) => {
          if (isHolidayWithYear(date) || isHolidayWithoutYear(date)) {
            return "holiday";
          }
          return null;
        }}
      />
      <ActivityCardList
        list={activityList}
        date={moment(selectedDate).format("YYYY-MM-DD")}
        club_nick={club_nick}
        memberId={memberId}
        crewId={crewId}
        adminLevel={adminLevel}
        isRestricted={isRestricted}
      />
    </div>
  );
}

export default ActivityCalendar;
